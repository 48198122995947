.Background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  min-width: 320px;
  height: 100%;
  background: linear-gradient(0deg, #eceef3, #eceef3), #ffffff;
}

.Dark .Background {
  background: var(--main-dark-color-darkest);
}

.Background > div {
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.BackgroundNoise {
  position: absolute !important;
  z-index: -99;
  background: url(../../../static/images/noise_small.png);
  background-repeat: repeat;
  mix-blend-mode: soft-light;
  opacity: 0.2;
}

.Background > div > div {
  z-index: -100;
}

.Dark .Background > div > div {
  mix-blend-mode: screen;
}

.MobileCircle1 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: 46%;
  top: -150px;

  border-radius: 301px;

  background: linear-gradient(
    281.82deg,
    #039ee4 26.63%,
    #312783 71.82%,
    #e6017d 118.74%
  );
  mix-blend-mode: multiply;
  filter: blur(42px);
}

.MobileCircle2 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: 55%;
  top: 34.73px;

  border-radius: 301px;

  background: linear-gradient(
    229.54deg,
    #039ee4 -2.24%,
    #312783 36.25%,
    #e6017d 76.22%
  );
  filter: blur(42px);
}

.MobileCircle3 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -547.1px;
  top: 614.57px;

  border-radius: 301px;

  background: rgba(24, 160, 251, 0.6);
  filter: blur(42px);
}

.MobileCircle4 {
  position: absolute;
  width: 502.57px;
  height: 502.57px;
  left: 77%;
  top: 814.94px;

  border-radius: 226px;

  background: linear-gradient(
    131.02deg,
    rgba(3, 158, 228, 0.5) -24.34%,
    rgba(49, 39, 131, 0.5) 30.42%,
    rgba(230, 1, 125, 0.5) 76.48%
  );
  mix-blend-mode: normal;
  filter: blur(42px);
}

.MobileCircle5 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -446.22px;
  top: 1265.62px;

  border-radius: 301px;

  background: rgba(230, 1, 125, 0.6);
  mix-blend-mode: multiply;
  filter: blur(67px);
  transform: matrix(1, -0.1, -0.1, -1, 0, 0);
}

.MobileCircle6 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: 53%;
  top: 1839.11px;

  border-radius: 301px;

  background: conic-gradient(
    from 49.86deg at 50% 56.61%,
    #e6017d -27.54deg,
    #039ee4 34.32deg,
    #312783 180.58deg,
    #e6017d 332.46deg,
    #039ee4 394.32deg
  );
  mix-blend-mode: multiply;
  filter: blur(72px);
  transform: matrix(-0.97, 0.26, 0.26, 0.97, 0, 0);
}

.MobileCircle7 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -350px;
  top: 2450px;

  border-radius: 301px;

  background: #18a0fb;
  filter: blur(42px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.DesktopCircle1 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: 30%;
  top: -145.73px;

  border-radius: 301px;

  background: linear-gradient(
    281.82deg,
    #039ee4 26.63%,
    #312783 71.82%,
    #e6017d 118.74%
  );
  mix-blend-mode: multiply;
  filter: blur(42px);
}

.DesktopCircle2 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: 55%;
  top: 167px;

  border-radius: 301px;

  background: linear-gradient(
    229.54deg,
    #039ee4 -2.24%,
    #312783 36.25%,
    #e6017d 76.22%
  );
  filter: blur(42px);
}

.DesktopCircle3 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -301.1px;
  top: 568.84px;

  border-radius: 301px;

  background: #18a0fb;
  filter: blur(42px);
}

.DesktopCircle4 {
  position: absolute;
  width: 502.57px;
  height: 502.57px;
  left: -153.94px;
  top: 769.2px;

  border-radius: 301px;

  background: linear-gradient(
    131.02deg,
    #039ee4 -24.34%,
    #312783 30.42%,
    #e6017d 76.48%
  );
  mix-blend-mode: normal !important;
  filter: blur(42px);
}

.DesktopCircle5 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: 55%;
  top: 1086.43px;

  border-radius: 301px;

  background: linear-gradient(
    270deg,
    #039ee4 9.53%,
    #312783 50.16%,
    #e6017d 92.35%
  );
  filter: blur(42px);
  transform: rotate(-180deg);

  z-index: -200 !important;
}

.DesktopCircle6 {
  position: absolute;
  width: 502.57px;
  height: 502.57px;
  left: 80%;
  top: 1358.34px;

  border-radius: 256px;

  background: #18a0fb;
  mix-blend-mode: normal !important;
  filter: blur(67px);
  transform: rotate(-180deg);
}

.DesktopCircle7 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -350.22px;
  top: 1400px;

  border-radius: 301px;

  background: #e6017d;
  mix-blend-mode: multiply;
  filter: blur(67px);
  transform: matrix(1, -0.1, -0.1, -1, 0, 0);
}

.Dark .DesktopCircle7 {
  top: 1600px;
}

.DesktopCircle8 {
  position: absolute;
  width: 640.14px;
  height: 640.14px;
  left: 70%;
  top: 1650px;

  border-radius: 320px;

  background: linear-gradient(
    250.88deg,
    #039ee4 4.11%,
    #312783 41.81%,
    #e6017d 80.96%
  );
  mix-blend-mode: multiply;
  filter: blur(42px);
  transform: rotate(75deg);

  z-index: -200 !important;
}

.DesktopCircle9 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -130px;
  top: 1800px;

  border-radius: 301px;

  background: conic-gradient(
    from 49.86deg at 50% 56.61%,
    #e6017d -27.54deg,
    #039ee4 34.32deg,
    #312783 180.58deg,
    #e6017d 332.46deg,
    #039ee4 394.32deg
  );
  mix-blend-mode: multiply;
  filter: blur(77px);
  transform: matrix(0.91, 0.41, 0.41, -0.91, 0, 0);
}

.Dark .DesktopCircle9 {
  top: 2050px;
  left: -350px;
}

.DesktopCircle10 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  right: -250px;
  bottom: -160px;

  border-radius: 301px;

  background: linear-gradient(180deg, #e6017d 35%, rgba(0, 0, 0, 0) 80%);
  filter: blur(42px);
}

.Dark .DesktopCircle10 {
  bottom: -500px;

  filter: blur(160px);
}

.DesktopCircle11 {
  position: absolute;
  width: 602.2px;
  height: 602.2px;
  left: -303.05px;
  bottom: -250px;

  border-radius: 301px;

  background: linear-gradient(180deg, #18a0fb 20%, rgba(0, 0, 0, 0) 58%);
  filter: blur(42px);
}

.Dark .DesktopCircle11 {
  bottom: -500px;

  filter: blur(160px);
}
