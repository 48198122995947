.Insecure {
  margin-bottom: 120px;
}

.Insecure > div:first-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Insecure > div > img {
  width: 100%;
}

.InsecureText {
  margin-bottom: 30px;
}

@media (min-width: 481px) {
  .Insecure {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 300px;
  }

  .Insecure > div > img {
    margin-bottom: -30px;
    min-width: 345px;
    width: 60%;
  }

  .InsecureText {
    max-width: 633px;
    margin-bottom: 40px;
    text-align: center;
  }

  .InsecureText > h2 {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 50px;
    line-height: 100%;
  }

  .InsecureText > p {
    margin: 0;
    font-size: 20px;
    line-height: 100%;
  }

  .InsecureText > p:nth-child(2) {
    margin-bottom: 22px;
  }
}
