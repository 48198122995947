.Button > button {
  position: relative;
  height: 46px;
  padding: 0 25px;
  border-radius: 23px;
  border: none;
  background-color: var(--main-dark-color);
  cursor: pointer;
}

.Button > button > span {
  color: var(--main-light-color);
  font-family: Space Grotesk;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

/* Loading spinner */
.Button.Loading > button > span {
  visibility: hidden;
}

.Button > button > div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Dark .Button > button {
  background-color: var(--main-light-color);
}

.Dark .Button > button > span {
  color: var(--main-dark-color) !important;
}

/* TODO Hack */
.Button.dark > button {
  background-color: var(--main-dark-color) !important;
}

.Button.dark > button > span {
  color: var(--main-light-color) !important;
}

.Button.secondary > button {
  background-color: rgb(250, 250, 250);
  border: 2px solid var(--main-dark-color);
}

.Button.secondary > button > span {
  color: var(--main-dark-color);
}

.Dark .Button.secondary > button {
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.38) 0%,
    rgba(255, 255, 255, 0.105) 100%
  );
  border: 2px solid var(--main-light-color);
}

.Dark .Button.secondary > button > span {
  color: var(--main-light-color) !important;
}

.Button.light > button {
  background-color: rgb(240, 238, 240);
  border: 2px solid rgba(49, 49, 49, 0.3);
}

.Button.light > button > span {
  color: rgba(49, 49, 49, 0.6);
}

@media (min-width: 481px) {
  .Button > button {
    height: 52px;
    padding: 0 26px;
    border-radius: 26px;
  }

  .Button > button > span {
    font-size: 15px;
    line-height: 20px;
  }
}

/* Animations */

.Button > button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: var(--main-dark-color-darker);
  transition: box-shadow 0.2s, background-color 0.2s;
}

.Button > button {
  box-shadow: 0;
  background-color: var(--main-dark-color);
  transition: box-shadow 0.2s, background-color 0.2s;
}

.Button.secondary > button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  background-color: var(--secondary-light-color);
  transition: box-shadow 0.2s, background-color 0.2s;
}

.Button.secondary > button {
  box-shadow: 0;
  background-color: var(--main-light-color-lighter);
  transition: box-shadow 0.2s, background-color 0.2s;
}

.Dark .Button > button:hover {
  background-color: var(--secondary-light-color);
}

.Dark .Button.secondary > button:hover {
  background-color: var(--main-dark-color);
}

.Button.light > button:hover {
  background-color: var(--main-light-color);
  transition: box-shadow 0.2s, background-color 0.2s;
}

@keyframes onClick {
  from {
  }
  to {
    box-shadow: 0px;
    transform: scale(0.95);
  }
}

.Button > button:active {
  animation: onClick 0.05s;
  animation-fill-mode: forwards;
}

.Button.secondary > button:active {
  animation: onClick 0.05s;
  animation-fill-mode: forwards;
}

/* No animation when loading */
.Button.loading > button:active {
  animation: none;
}
