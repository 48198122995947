.Clients {
  margin-bottom: 120px;
  border-bottom: 1px solid var(--main-dark-color);
}

.Dark .Clients {
  border-bottom: 1px solid var(--main-light-color);
}

/* TODO this loads more date then needed */
@media (min-width: 601px) {
  .ClientsDesktop {
    display: relative;
  }
  .ClientsMobile {
    display: none;
  }
}

@media (max-width: 600px) {
  .ClientsDesktop {
    display: none;
  }
  .ClientsMobile {
    display: relative;
  }
}
