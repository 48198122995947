.WhatWeDo {
  margin-bottom: 110px;
  padding-bottom: 108px;
  border-bottom: 1px solid var(--main-dark-color);
}

.Dark .WhatWeDo {
  border-bottom: 1px solid var(--main-light-color);
}

.WhatWeDo > .Text > p {
  margin-bottom: 0;
}

.WhatWeDo > .Text {
  margin-bottom: 37px;
}

@media (min-width: 481px) {
  .WhatWeDo > .Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 47px;
  }

  .WhatWeDo > .Text > h2 {
    margin-bottom: 20px;
    font-size: 50px;
    line-height: 100%;
  }

  .WhatWeDo > .Text > p {
    margin-top: 0;
    max-width: 636px;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
  }

  .WeDoList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media (min-width: 1024px) {
  .WeDoList {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
