.ClientRow {
  height: 130px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

@media (min-width: 601px) {
  .ClientRow {
    height: 182px;
  }
}
