.Legal {
  display: flex;
  justify-content: space-between;
}

.Legal > span {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 100%;
  color: var(--main-dark-color);
}

@media (min-width: 481px) {
  .Legal > span {
    font-size: 12px;
    line-height: 100%;
  }
}
