.Client {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85px;
}

.ClientIcon {
  height: 44px;
  margin-bottom: 5px;
}

.ClientIcon > svg {
  height: 100%;
}

.Client > span {
  font-family: 'SyneBold';
  font-size: 13px;
  line-height: 12px;
  text-align: center;
  color: var(--main-dark-color);
}

@media (min-width: 601px) {
  .ClientIcon {
    margin-bottom: 12.5px;
    height: 75px;
  }

  .ClientIcon > svg {
    height: 100%;
  }

  .Client > span {
    font-size: 20px;
    line-height: 19px;
  }
}
