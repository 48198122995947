.ModalTermsLabel {
  display: flex;
  /* align-items: center; */

  margin-bottom: 40px !important;

  font-family: 'Space Grotesk' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 120% !important;
  letter-spacing: 0.03em;
}

.ModalCheckbox {
  margin-right: 13px;
  transform: scale(1.4615);
}

.ModalTermsLabel > p {
  font-size: 12px;
  text-indent: 0;
  margin: 2.45px 0 0 0;
}

.ModalTermsLabel > p > a {
  text-decoration: none;
}

.ModalTermsLabelError {
  color: #ff0000 !important;
}

.ModalTermsLabelError > p > a {
  text-decoration: none;
  color: #ff0000 !important;
}
