.Hero {
  padding-top: 20px;
  margin-bottom: 120px;
}

.HeroCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeroCentered > img {
  width: 100%;
  margin-bottom: -70px;
  z-index: -1;
}

.Dark .HeroCentered > img {
  margin-bottom: -20px;
}

.Hero h1 {
  margin-bottom: 0;
  font-size: 9vw;
}

.HeroSecondLine {
  /* height: 99px; */
  margin-bottom: 12px;
}

.HeroSecondLine > h1 {
  margin-top: 0;
}

.Hero > p {
  margin-bottom: 20px;
}

@media (min-width: 560px) {
  .Hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 160px;
  }

  .Hero h1 {
    text-align: center;
    /* font-size: 70px; */
    line-height: 92%;
  }

  .Hero > p {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    line-height: 100%;
  }

  .HeroCentered > img {
    width: 50%;
    min-width: 345px;
    margin-left: 75px;
    margin-bottom: -135px;
  }

  .Dark .HeroCentered > img {
    margin-bottom: -40px;
  }
}

@media (max-width: 340px) {
  .Hero h1 {
    font-size: 30px;
  }
}

@media (min-width: 775px) {
  .Hero h1 {
    font-size: 70px;
  }
}
