.Toggle {
  height: 27px;
}

.react-switch-bg {
  background-color: var(--main-dark-color) !important;
}

.react-switch-handle {
  background-color: var(--secondary-light-color) !important;
}

.Dark .react-switch-bg {
  background-color: var(--secondary-light-color) !important;
}

.Dark .react-switch-handle {
  background-color: var(--main-dark-color) !important;
}
