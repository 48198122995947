.TextInputErrorLabel {
  margin-top: -25px;
  margin-bottom: 9px !important;
  color: #ff0000 !important;
}

.TextInputError {
  margin-top: 0 !important;
  margin-bottom: 30px !important;
  border: 2px solid #ff0000 !important;
}
