@font-face {
  font-family: 'Space Grotesk';
  src: local('Space Grotesk'),
    url(./static/fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'SyneBold';
  src: local('SyneBold'),
    url(./static/fonts/Syne/Syne-Bold.ttf) format('truetype');
  font-weight: bold;
}

:root {
  --main-dark-color: rgb(49, 49, 49);
  --main-dark-color-opacity-a: rgb(49, 49, 49, 0.5);
  --main-dark-color-opacity-b: rgba(49, 49, 49, 0.8);
  --main-dark-color-opacity-c: rgba(49, 49, 49, 0.9);
  --main-dark-color-opacity-d: rgba(49, 49, 49, 0.3);
  --main-dark-color-darker: rgb(39, 39, 39);
  --main-dark-color-darkest: rgb(0, 0, 0);

  --main-light-color: rgba(236, 238, 242);
  --main-light-color-lighter: rgba(246, 248, 252);

  --secondary-light-color: rgba(255, 255, 255);
}

body {
  margin: 0;
  font-family: 'Space Grotesk', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: 'Space Grotesk';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  margin: 12px 0;
}

h1 {
  font-family: 'SyneBold';
  font-size: 37px;
  line-height: 90%;
  margin-bottom: 12px;
}

h2 {
  font-family: 'SyneBold';
  font-size: 30px;
  line-height: 88.9%;
  margin-bottom: 12px;
}

h3 {
  font-family: 'SyneBold';
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 8px;
}

hr {
  border-top: 0.5px solid var(--main-dark-color);
}

/* Dark Mode */

.Dark p {
  color: var(--main-light-color);
}

.Dark h1 {
  color: var(--main-light-color);
}

.Dark h2 {
  color: var(--main-light-color);
}

.Dark h3 {
  color: var(--main-light-color);
}

.Dark span {
  color: var(--main-light-color) !important;
}
