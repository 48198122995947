.Footer {
  margin: 0 15px;
  padding-bottom: 40px;
}

.Centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 120px;
}

.Centered > img {
  width: 266px;
}

.Centered > .Text > p {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 8px;
}

.Centered > .Text {
  max-width: 380px;
  margin-bottom: 20px;
}

.SocialAndTheme {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 37px;
}

.SocialAndTheme > .Social {
  width: 142px;
}

/* Desktop View  */

.FooterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FooterRow > img {
  width: 380px;
}

.FooterLogoMargin {
  margin-bottom: 6px;
}

@media (min-width: 821px) {
  .Footer {
    margin: 0 61px;
    padding-bottom: 40px;
  }

  .FooterText {
    width: 350px;
    margin-left: 8px;
    margin-bottom: 37px;
  }

  .FooterText > p {
    margin: 0;
  }

  .FooterText > p:first-child {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 15px;
    margin-bottom: 8px;
  }

  .FooterRow > .Social {
    width: 142px;
  }
}
