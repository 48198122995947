.WeDoContent {
  display: flex;
  align-items: flex-start;
}

.WeDoContent > .WeDoIcon > svg {
  width: 36px;
  margin: 14px 15px 0 0;
  color: var(--main-dark-color);
}

.WeDoContent > div > h3 {
  margin-top: 0px;
  letter-spacing: 0.025em;
  color: var(--main-dark-color);
}

.Dark .WeDoContent > div > h3 {
  color: var(--main-light-color);
}

.WeDoContent > div > p {
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.025em;
  color: var(--main-dark-color);
}

.Dark .WeDoContent > div > p {
  color: var(--main-light-color);
}

.WeDoContent > .Boarder > p {
  margin-bottom: 25px !important;
  padding-bottom: 28px;
  border-bottom: 0.5px solid var(--main-dark-color-opacity-a);
}

@media (min-width: 870px) {
  .WeDo {
    width: calc(50% - 16px);
    margin-bottom: 35px;
    background: linear-gradient(
      152.97deg,
      hsla(0, 0%, 100%, 0.7),
      hsla(0, 0%, 100%, 0.55)
    );
    box-shadow: 0px -6px 14px rgba(255, 255, 255, 0.25);
    border-radius: 20px;
  }

  .Dark .WeDo {
    background: linear-gradient(
      152.97deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: drop-shadow(0px -6px 14px rgba(255, 255, 255, 0.15));
    backdrop-filter: blur(42px);
    box-shadow: 0px -6px 14px 0px rgba(255, 255, 255, 0.15);
    z-index: -1;
  }

  .WeDoContent {
    align-items: center;
    padding: 0 20px 0 0;
  }

  .WeDoContent > div > h3 {
    font-size: 26px;
    margin: 31.5px 0 12px 0;
  }

  .WeDoContent > div > p {
    margin: 0 0 28px 0;
  }

  .WeDoContent > .Boarder > p {
    margin: 0 0 28px 0 !important;
    padding-bottom: 0;
    border-bottom: none;
  }
}
