.ModalContainer {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;

  overflow-y: scroll;
  overflow-x: scroll;

  z-index: 100;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ModalContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ModalContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Modal {
  display: flex;
  flex-direction: column;

  width: 90%;
  min-width: 290px;
  max-width: 872px;

  margin: 30px auto;

  background: rgb(240, 238, 240);
  border: 1px solid rgba(255, 255, 255, 0.25);
  box-shadow: 0px -6px 14px rgba(255, 255, 255, 0.25);

  border-radius: 20px;
}

.ModalTabs {
  display: flex;
  flex-direction: row;
}

.ModalTabs > button {
  width: 50%;
  height: 39px;

  padding-left: 25px;

  font-family: Space Grotesk;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: rgba(49, 49, 49, 0.7);

  border: none;
  cursor: pointer;
}

.ModalTabInactive {
  background: rgb(212, 210, 225);
}

.ModalTabs > button:first-child {
  border-radius: 20px 0 0 0;
  margin-right: 0.5px;
}

.ModalTabs > button:last-child {
  border-radius: 0 20px 0 0;
  margin-left: 0.5px;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px 25px 30px 25px;
}

.ModalContent > div > h2 {
  margin-top: 0;
}

.Dark .ModalContent > div > h2 {
  color: var(--main-darken-color);
}

.Dark .ModalContent > div > p {
  color: var(--main-darken-color);
}

.Dark .ModalContent > div > h3 {
  color: var(--main-darken-color);
}

.ModalContent > div > p {
  margin-bottom: 30px;
}

.ModalForm {
  display: flex;
  flex-direction: column;
}

.ModalForm > label {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  letter-spacing: 0.03em;
  text-indent: 5px;

  color: var(--main-dark-color-opacity-c);

  margin-bottom: 5px;
}

.Dark .ModalTermsLabel > p {
  color: var(--main-darken-color);
}

.Dark .ModalTermsLabel > p > span {
  color: var(--main-darken-color) !important;
}

.ModalTermsLabel > p > span {
  cursor: pointer;
}

.ModalForm > textarea {
  padding: 15px 25px 14px 15px;
  margin-top: 1px;
  margin-bottom: 31px;
  height: 18px;

  font-family: Space Grotesk;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-indent: 5px;

  color: var(--main-dark-color-opacity-c);
  border: 1px solid var(--main-dark-color-opacity-d);
  border-radius: 33px;

  resize: none;
}

.ModalForm > textarea::-webkit-scrollbar {
  display: none;
}

.ModalForm > textarea[name='message'] {
  height: 120px;
}

.ModalForm > textarea:focus {
  margin-top: 0;
  margin-bottom: 30px;

  border: 2px solid var(--main-dark-color);
  outline: none;
}

.ModalButtons {
  display: flex;
  flex-direction: row;
}

.ModalButtons > *:first-child {
  margin-right: 10px;
}

@media (min-width: 400px) {
  .ModalTabs > button {
    font-size: 13px;
  }
}

/* Animations */

.ModalContainer {
  animation: darken 1s forwards;
}

.Modal {
  animation: slideIn 1s;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes darken {
  from {
    background-color: none;
  }
  to {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(20px);
  }
}
