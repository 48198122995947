.Navigation {
  position: relative;
  width: 100%;
  height: 80px;
}

.Navigation > img {
  position: absolute;
  width: 150px;
  left: 14px;
  top: 16px;
}

/* Desktop View */
.Navigation > div {
  position: absolute;
  right: 60px;
  top: 56px;
  font-family: 'Space Grotesk';
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--main-dark-color-opacity-b);
}

.Navigation > div > span {
  cursor: pointer;
}

@media (min-width: 560px) {
  .Navigation {
    position: fixed;
    height: 130px;
  }

  .Navigation > img {
    position: absolute;
    width: 175px;
    left: 60px;
    top: 51px;
  }
}
