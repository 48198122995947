.ThemeSwitch {
  display: flex;
  align-items: center;
}

.ThemeSwitch > .Toggle {
  margin: 0 6px;
}

.ThemeSwitch > span {
  font-family: Space Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
}
