.Social {
  display: flex;
  justify-content: space-between;
  height: 19px;
}

@media (min-width: 870px) {
  .Social {
    height: 24px;
  }
}
