.App {
  min-width: 320px;
}

.Content {
  margin: 0 15px;
}

@media (min-width: 481px) {
  .Content {
    width: 90%;
    max-width: 1095px;
    margin: 0 auto;
  }
}
