.TextDivider {
  display: flex;
}

.TextDivider > span {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.2em;
  padding: 0 15px;
}

.TextDivider > div {
  flex-grow: 1;
}

.Divider {
  display: flex;
  flex-direction: column;
}

.Divider > div {
  flex-grow: 1;
}

.Divider > div:first-child {
  border-bottom: 1px solid var(--main-dark-color);
}

.Dark .Divider > div:first-child {
  border-bottom: 1px solid var(--main-light-color);
}

@media (min-width: 481px) {
  .TextDivider > span {
    font-size: 16px;
  }
}
